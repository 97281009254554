import { useState } from 'react'
import { ReactComponent as LogoMazza } from './assets/logo-mazzavelas.svg'
import BGMazzaVelas from './assets/bg-main-image.jpeg'

function App() {
  const [container, setContainer] = useState(0)
  const [containerFilled, setContainerFilled] = useState(0)
  const [containerQuantity, setContainerQuantity] = useState()
  const [essencePercentual, setEssencePercentual] = useState()

  const waxCalculed = ((containerFilled - container) * 0.85) * containerQuantity
  const totalEssence = waxCalculed * (essencePercentual / 100)

  return (
    <>
      <header className="flex justify-center items-center flex-wrap">
        <LogoMazza />
        <div className="flex w-full justify-center mb-6 px-4">
          <a className="text-slate-600 no-underline" href="https://mazzavelasaromaticas.com.br/" target="_blank">
            <p className="font-sans text-slate-600 mr-4 text-md sm:text-lg text-center">Loja Mazza Velas</p>
          </a>
          <a className="text-slate-600 no-underline" href="https://lpcomunidade.mazzavelasaromaticas.com.br/" target="_blank">
            <p className="font-sans text-slate-600 mr-4 text-md sm:text-lg text-center">Comunidade Mazza Help!</p>
          </a>
          <a className="text-slate-600 no-underline" href="https://docs.google.com/spreadsheets/d/19SmBb2AFCfDIDwy79vjdelwoaIlDGIUWh7gOkJfpeaQ/edit?pli=1&gid=0#gid=0" target="_blank">
            <p className="font-sans text-slate-600 text-md sm:text-lg text-center">Mazza Indica</p>
          </a>
        </div>
      </header>
      <section className="flex justify-center w-full px-4">
        <div className="w-full sm:w-3/4 flex items-center justify-around bg-white shadow-lg rounded-3xl px-3 pt-4">
          <img
            className="hidden sm:block rounded-t-full w-1/3 border-[#ccd1bf] border-solid border-4 border-b-0"
            src={BGMazzaVelas}
            alt="Mazza Velas Aromaticas"
          />
          <div className="w-full sm:w-1/2">
            <div className="mb-4">
              <h1 className="mb-3 font-sans text-slate-600 text-lg font-bold">Calculadora de cera</h1>
              <h3 className="mb-1 font-sans text-slate-600 text-sm">Para fazer o cálculo preencha os campos apenas com números e lembre-se que os valores são sempre em gramas. Assim você saberá certinho a quantidade para usar na sua produção.</h3>
              <p className="font-sans text-orange-600 text-xs font-bold italic">Os valores apresentados nos resultados estão em gramas.</p>
            </div>
            <div>
              <div className="mb-4 flex justify-between">
                <p className="font-sans text-slate-600 text-sm">Peso do recipiente vazio*</p>
                <input
                  className="border-[#ccd1bf] border-solid border-2"
                  name="container"
                  type="number"
                  onChange={e => setContainer(e.target.value)}
                />
              </div>
              <div className="mb-4 flex justify-between">
                <p className="font-sans text-slate-600 text-sm">Peso do recipiente preenchido com água*</p>
                <input
                  className="border-[#ccd1bf] border-solid border-2"
                  name="container-filled"
                  type="number"
                  onChange={e => setContainerFilled(e.target.value)}
                />
              </div>
              <div className="mb-4 flex justify-between">
                <p className="font-sans text-slate-600 text-sm">Quantidade de frascos*</p>
                <input
                  className="border-[#ccd1bf] border-solid border-2"
                  name="container-quantity"
                  type="number"
                  onChange={e => setContainerQuantity(e.target.value)}
                />
              </div>
              <hr />
              <div className="py-4 flex justify-between items-center">
                <p className="font-sans text-slate-600 text-sm">Essa é a quantidade de cera que você vai precisar</p>
                <h1 className="font-sans text-slate-600 text-3xl font-bold">{waxCalculed > 0 ? waxCalculed.toFixed(0) : 0}g</h1>
              </div>
              <hr />
              <div className="my-4">
                <h1 className="mb-1 font-sans text-slate-600 text-lg font-bold">Quantidade de essência</h1>
                <p className="font-sans text-orange-600 font-bold text-xs italic">Os valores apresentados nos resultados estão em gramas.</p>
              </div>
              <div>
                <div className="mb-4 flex justify-between items-center">
                  <p className="font-sans text-slate-600 text-sm">Total de cera calculado</p>
                  <h1 className="font-sans text-slate-600 text-3xl font-bold">{waxCalculed > 0 ? waxCalculed.toFixed(0) : 0}g</h1>
                </div>
                <div className="mb-4 flex justify-between">
                  <p className="font-sans text-slate-600 text-sm">Percentual de essência*</p>
                  <input
                    className="border-[#ccd1bf] border-solid border-2"
                    name="essence-percentual"
                    type="number"
                    max={100}
                    onChange={e => setEssencePercentual(e.target.value)}
                  />
                </div>
                <hr />
                <div className="py-4 flex justify-between items-center">
                  <p className="font-sans text-slate-600 text-sm">Essa é a quantidade de essência que você vai precisar</p>
                  <h1 className="font-sans text-slate-600 text-3xl font-bold">{totalEssence > 0 ? totalEssence.toFixed(0) : 0}g</h1>
                </div>
                <hr />
                <p className="my-4 font-sans text-orange-600 text-xs font-bold italic">Importante! Preencha todos os campos com *</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="flex justify-center py-4">
        <p className="font-sans text-slate-600 text-xs font-bold italic">&copy; Mazza Velas Aromaticas - 2024</p>
      </footer>
    </>
  );
}

export default App;
